$a1_primary:#669047; 

@media all {
  .hotspot-image {
    max-width: 100%;
    height: auto;
  }

  .hotspots {
    display: inline-block;
    position: relative;

    .hotspot {
      position: absolute;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: #fff;
      border-radius: 20px;
      color: $a1_primary;

      &:hover{
        background: $a1_primary;
        color: #fff;
      }

      .hotspot-pulse {
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
        border-radius: 50%;
      }
      
      img {
        width: 100%;
        height: 100%;

        &.heartbeat {
          animation: heartbeat 2s infinite;
        }

        &.bounce {
          animation: bounce 1s infinite;
        }
      }
    }
  }

  .fancybox-button {
    svg {
      filter: drop-shadow(0 0 0px rgba(255,255,255,1));
    }
  }

  .fancybox-content {
    &.fancybox-hotspot-content {
      padding: 0;
      max-width: 80vw;
      min-width: 320px;
      opacity: 0;
      background-color: #fff;
    }

    .display-flex-row {
      @media (min-width: 1024px) {
        display: flex;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .fancybox-copy {
      h2, h3, h4, h5, h6 {
        margin: 16px 16px 8px;
      }

      p {
        margin: 8px 16px 16px;
      }

      @media (min-width: 1024px) {
        h2, h3, h4, h5, h6 {
          margin: 32px 32px 16px;
        }

        p {
          margin: 16px 32px 32px;
        }
      }
    }
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes heartbeat {
  0% {transform: scale(1);}
  25% {transform: scale(.97);}
  35% {transform: scale(.9);}
  45% {transform: scale(1.1);}
  55% {transform: scale(.9);}
  65% {transform: scale(1.1);}
  75% {transform: scale(1.03);}
  100% {transform: scale(1);}
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}